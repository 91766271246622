import Main from "./Component/Main/Main";
import Sidebar from "./Component/Sidebar/Sidebar";
import './App.css'
function App() {
  return (
    <div className="App">
    <Sidebar/>
    <Main/>
    </div>
  );
}

export default App;
